@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700'); // feel free to change this font type at any momment.
$primary: #c86e34; /* MAIN COLOR */
$secondary: #484d5b; /* SECONDARY COLOR */
$gray: #666; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$footerLinks: #d5d5d5;

@import url('https://fonts.googleapis.com/css?family=Poiret+One|Sigmar+One|Special+Elite');

h1 {
	font-family: 'Sigmar One', sans-serif;
	font-size: 3.5vw;

	@media (max-width: 767px) {
		font-size: 4vw;
	}
}

h2,h3,h4 {
	font-family: 'Poiret One', sans-serif;
	font-weight: bold;
	letter-spacing: 1px;
	font-size: 2.1em;
	line-height: 40px;
	
	@media (max-width: 1400px) {
		font-size: 1.75vw;
		line-height: 27px;
	}


	@media (max-width: 767px) {
		font-size: 3.5vw;
		line-height: 20px;
	}
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


body {
	font-size: 1.5em;
}

nav {
	z-index: 10;
}

.navbar-brand {
    height: 100px; // this is what determine the height of the nav
    padding: 5px;
		@media(max-width: 767px){
			height: 70px; // nav height mobile,(feel free to change this values as you please)
			.logo {
				max-height: 60px; // logo height changed on mobile
			}
		}
}


.navbar {
	background: transparent;
	border: none;
	transition: 1s all ease;

	&.darkNav {
		background: rgba($blk, 0.8);
	}

}


.navbar .navbar-nav {
		@media (max-width: 767px) {
		    text-align: center;
		}

	> li > a {
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: white;
			transition: 1s all ease;


			@media (max-width: 767px) {
				margin-top: 0;
			    display: inline-block;
			    height: 25px;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: white;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: white;
			  outline: 0;
			}
		}
}


.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


// here start mixins ---- this is a style for buttons you can add it to your a tag like this (@include btn, look at the .btn-default style), these values can be changed anytime you need to.
@mixin btn{
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: 0px;
	background: $primary;
	color: $wht;
	padding: 0.5em 1em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: 2em 0em;

	&:hover {
		background: lighten($primary,10%);
		color: $wht;
		box-shadow: 0px 0px 3px lighten($blk,20%);
		text-decoration: none;
	}
}


.btn-default {
	@include btn;
}
// mixins end here


// sections start
.section-a {
	padding: 60px;
	margin-top: 100px; // this marging  will depend on the height of the nav, (if you have a bigger or smaller nav feel free to change it)  'if you need a transparent nav and see the img change margin to padding'
}
// sections end


.modal-dialog {
	max-width: 500px;
	width: 100%;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	a.btn, a.btn-default, button {
		@include btn
		margin: 0;
		display: block;
	}
}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}


footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 15px;
	
	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;

		&:hover {
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}
		
		&:focus {
			color: $footerLinks;
			outline: 0 !important;
 			text-decoration: none;
		}		
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}	
}



.logo {
	max-width: 250px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    }
}



.row {
	padding-top: 3em;
	padding-bottom: 3em;
}









@keyframes bannerImg {
	0% {
		background: url(../img/banner-min.jpg) no-repeat top;
		background-size: cover;
		-webkit-backface-visibility: hidden;
		-webkit-transform-style: preserve-3d;

		@media (max-width: 767px) {
			background-position: bottom;
		}
	}

	35% {
		background: url(../img/banner-min.jpg) no-repeat top;
		background-size: cover;
		-webkit-backface-visibility: hidden;
		-webkit-transform-style: preserve-3d;
		@media (max-width: 767px) {
			background: url(../img/banner-min.jpg) no-repeat bottom;
		}	
	}

	50% {
		background: url(../img/banner2-min.jpg) no-repeat bottom;
		background-size: cover;
		-webkit-backface-visibility: hidden;
		-webkit-transform-style: preserve-3d;
	}


	85% {
		background: url(../img/banner2-min.jpg) no-repeat bottom;
		background-size: cover;
		-webkit-backface-visibility: hidden;
		-webkit-transform-style: preserve-3d;
	}

	
	100% {
		background: url(../img/banner-min.jpg) no-repeat top;
		background-size: cover;
		-webkit-backface-visibility: hidden;
		-webkit-transform-style: preserve-3d;
	}
	
}



.row.bannerBg {
	padding: 0em;
}

.banner {
	background-color: black;
    background: linear-gradient(
		rgba(55,55,55,0.5),
		rgba(55,55,55,0.5)
		),
		url(../img/banner.jpg) no-repeat;	
	background-size: cover;
	padding: 30em 0em 23em 0em ;
	text-shadow: 1px 1px 3px $blk;
	color: $wht;
	margin-top: -8em;
	-webkit-backface-visibility: hidden;
	-webkit-transform-style: preserve-3d;
	animation: bannerImg 15s ease-in-out infinite;	


	@media (max-width: 990px) {
		padding: 24em 0em 16em;
	}

	@media (max-width: 767px) {
		padding: 22em 0em 12em;
	}	

	h1 { text-transform: uppercase; }
	
}

.bannerBg {
	background-color: black;
	-webkit-backface-visibility: hidden;
	-webkit-transform-style: preserve-3d;	
}

hr {
	max-width: 290px;
	width: 100%;
	border:2px solid $wht;
	border-radius: 10px;
}


.fade.in {
	background: linear-gradient(
	rgba(55,55,55,0.75),
	rgba(55,55,55,0.75)
	),
	url(../img/fade.jpg) no-repeat right;
	background-size: cover;
}


.flex {
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: 1200px) {
		// display: inline-block;  /* may need to be inline-block */
		position: relative;

		h3 {
			margin-top: 2em;
		}
	}
		@media (max-width: 767px) {
			position: static;
		}

		.center {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-right: -50%;
			transform: translate(0,-50%);

			@media (max-width: 767px) {

				position: static;
				transform: translate(0,0);
			}
		}
	
		@media (max-width: 990px) {
		display: inline-block;  /* may need to be inline-block */

			h3 {
				margin-top: 2em;
				font-size: 2.5vw;
				line-height: 25px;
			}	
		}
}


@keyframes colorsAnimation {
	0% {
		clip-path: circle(100px at 55% 57%);
		transform: rotateZ(0deg) scale(0.9);
	}

	100% {
		clip-path: circle(400px at 50% 50%);
		transform: rotateZ(10deg) scale(1);

	}
}

#colorsSection {
	position: relative;

	.colorsImg {
		animation: colorsAnimation 10s ease-in-out infinite alternate;

		@media (max-width: 990px) {
			max-width: 50%;
		}

		@media (max-width: 500px) {
			max-width: 75%;
		}		
	}

	.colorsTxt {
		position: absolute;
		left: 36%;
		top: 50%;
		font-size: 1.6vw;

		@media (max-width: 990px) {
			left: 50%;
			font-size: 3vw;
		}
	}
}


.bgSec {
	background: linear-gradient(
	rgba(55,55,55,0.7),
	rgba(55,55,55,0.7)
	),
	url(../img/bgSec.png) no-repeat top;
	background-size: cover;
	color: white;
	padding: 8em 0em;

	@media (max-width: 767px){
		padding: 4em 0em;

		img {
			max-width: 70%;
			margin: 0 auto;
		}
	}
}

.primaryTxt {
	color: $primary;
}